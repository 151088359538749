<template>
  Borrows
  <span
    v-tooltip="'KENP Read / KENPC. For users in KDP Select, it shows the estimated number of full reads.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
